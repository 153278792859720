import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {PostCouponsByIdVerifyResponse} from '@backend-api/coupons-verify/post-coupons-by-id-verify.response';
import {PostOrderRequest} from '@backend-api/order/post-order.request';
import {OrderableTraining} from '@backend-api/orderable-trainings/get-orderable-trainings.response';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, first, take} from 'rxjs/operators';
import {fadeInAnimation, fadeInOutAnimation} from 'src/app/animations';
import {selectCampaignParam} from 'src/app/shared/shared.selectors';
import {GenericDialogComponent, GenericDialogData} from 'src/app/ui-components/generic-dialog/generic-dialog.component';
import {validateEmail} from '../../../forgot-password/forgot-password.component';
import {Language} from '../../user/user.data.service';
import {checkCouponCode, sendOrder, trainingActions, verifyOrderCampaign} from '../order.actions';
import {OrderState} from '../order.reducer';
import {
  selectAllAvailableTrainings,
  selectCouponChecking,
  selectCouponData,
  selectCouponInvalid,
  selectIsOrderPlacedSuccessfully,
  selectOrderCampaignState,
  selectOrderErrorMessage,
  selectTrainingLoadHasError,
} from '../order.selectors';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: [fadeInAnimation, fadeInOutAnimation],
})
export class OrderComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('@fadeInAnimation') loadAnimation = true;

  @ViewChild('stepper') private readonly _stepper?: MatStepper;

  readonly availableTrainings$ = this._store$.select(selectAllAvailableTrainings);
  readonly trainingLoadError$ = this._store$.select(selectTrainingLoadHasError);
  readonly campaign$ = this._store$.select(selectCampaignParam);
  readonly coupon$ = this._store$.select(selectCouponData);
  readonly orderCampaignState$ = this._store$.select(selectOrderCampaignState);
  // needed for forms-error
  readonly couponInvalid$ = this._store$.select(selectCouponInvalid);
  readonly couponChecking$ = this._store$.select(selectCouponChecking);
  private readonly _orderPlaced$ = this._store$.select(selectIsOrderPlacedSuccessfully);
  private readonly _orderErrorMessage$ = this._store$.select(selectOrderErrorMessage);
  private readonly _subscriptions = new Subscription();

  orderForm = this._formBuilder.group({
    bookingsStep: this._formBuilder.group({
      bookings: [[], [Validators.required]],
      coupon: [''],
      // honeypots
      hCheckbox: [false],
      hInput: [''],
    }),
    userStep: this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, validateEmail]],
    }),
    invoiceStep: this._formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, validateEmail]],
      address: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      city: ['', [Validators.required]],
      acceptTerms: [false, [Validators.required]],
    }),
  });
  // for the navigation
  isNextAvailable?: boolean;
  isBackAvailable?: boolean;
  isFinishAvailable?: boolean;
  // for calculating shown prices
  private discount?: PostCouponsByIdVerifyResponse;
  strikethroughPrice?: number;
  priceTotal = 0;
  campaignCode?: string;
  currencyCode?: string;
  selectedLanguage: Language = 'de';
  // for the honeypots
  private readonly _hTimestamp = Date.now();

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _store$: Store<OrderState>,
    private readonly _translateService: TranslateService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog
  ) {
    // this page is supposed to enforce german language regardless of users browserlang
    this._translateService.use('de' as Language);
    this.selectedLanguage = this._translateService.currentLang as Language;
  }

  ngOnInit(): void {
    this._store$.dispatch(trainingActions.execute({params: undefined}));
  }

  ngAfterViewInit() {
    // as we need UI-State to check this, BUT have to trigger this initially, use Timeout
    setTimeout(() => {
      this.checkAvailability();
    }, 0);
    if (!!this.bookings) {
      this._subscriptions.add(
        // as soon as either the selection or the coupon-state changes, we have to react, as this changes the prices
        combineLatest([this.bookings.valueChanges as Observable<OrderableTraining[]>, this.coupon$])
          .pipe(distinctUntilChanged())
          .subscribe(([bookings, coupon]) => {
            // firstly, check if there is a valid coupon and what kind of discount this is
            if (!!coupon && !!coupon.amount_off) {
              this.discount = coupon;
            } else {
              this.discount = undefined;
            }
            // then, calculate the prices, given the (possibly changed) trainings
            this.calculateTotal(bookings);
          })
      );
    }
    // for the submission, we also need the current campaign-key, so put it into a var asap
    // also trigger verification which will fetch the intro-content, if available
    this.campaign$.pipe(distinctUntilChanged(), take(1)).subscribe((token) => {
      this.campaignCode = token;
      token = token || 'default';

      this._store$.dispatch(verifyOrderCampaign({key: token}));
    });

    this._subscriptions.add(
      // We need the currency code in the template for the currency-pipe, extract it from the first training
      this.availableTrainings$.pipe(first((trainings) => !!trainings && trainings.length > 0)).subscribe((trainings) => {
        // actually prevented by `first` above, but...
        if (!!trainings) {
          // just use the first training
          // uppercase is mandatory!
          return (this.currencyCode = trainings[0].gross_price_currency.toUpperCase());
        }
      })
    );

    this._subscriptions.add(
      // we want to show a mat-error on the input when coupon-validation fails in the BE
      this.couponInvalid$.pipe(distinctUntilChanged()).subscribe((isInvalid) => {
        // selector return false if invalid and undefined in any other case
        if (!!isInvalid) {
          this.coupon?.setErrors({invalid: true});
        } else {
          // set errors to `null`. May has to be changed if (for some reason), this field can have other errors
          this.coupon?.setErrors(null);
        }
      })
    );

    this._subscriptions.add(
      combineLatest([this._orderPlaced$, this._orderErrorMessage$])
        .pipe(
          filter(([placed]) => placed !== undefined),
          distinctUntilChanged(),
          // both observables might not be synchronous enough, we only want one dialog
          debounceTime(50)
        )
        .subscribe(([orderPlaced, errorMessage]) => {
          if (orderPlaced === true) {
            this._router.navigateByUrl('/checkout');
          } else {
            // The message wil be mapped by effects and contain the 'email'-attribute incase we got the email-error during order-placement
            // IF we had the email-error, set values accordingly, else use generic values
            const errorKey = errorMessage === 'email' ? 'ORDER.ERROR.EMAIL.PRE' : 'ERROR.GENERIC';
            const errorSuffix = errorMessage === 'email' ? 'ORDER.ERROR.EMAIL.POST' : undefined;
            this._dialog.open(GenericDialogComponent, {
              // The generic dialog takes translated strings as values
              data: {
                title: this._translateService.instant('ERROR.ERROR_HEADER'),
                message: this._translateService.instant(errorKey),
                suffix: errorSuffix ? this._translateService.instant(errorSuffix) : undefined,
              } as GenericDialogData,
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  nextStep() {
    this._stepper?.next();
  }

  previousStep() {
    this._stepper?.previous();
  }

  /**
   * Submits the forms (and other needed) data to the BE in order to send this order.
   * This also sends a time-delta using the set timestamp and the existing honeypot-inputs.
   */
  submit() {
    if (!this.orderForm.valid) return false;
    // to better work with the trainings-array
    const mappedTrainings = (this.selectedTrainings as OrderableTraining[]).map((training) => training.slug);
    // Collect all the needed data
    const orderData: PostOrderRequest = {
      first_name: this.userFirstName?.value as string,
      last_name: this.userLastName?.value as string,
      email: this.userEmail?.value,
      language: this._translateService.currentLang as Language,
      campaign_key: this.campaignCode || 'default',
      billing_name: this.invoiceName?.value,
      billing_email: this.invoiceEmail?.value,
      billing_address: this.invoiceAddress?.value,
      billing_postal_code: this.invoiceZip?.value,
      billing_city: this.invoiceCity?.value,
      // The schema generates this awesome structure...
      trainings: [mappedTrainings[0], ...mappedTrainings.slice(1)],
      coupon: this.coupon?.value,
      // flag 'temporarily' removed
      allows_contact: false,
      h_delta: Date.now() - this._hTimestamp,
      h_checkbox: this.hCheckbox?.value,
      h_input: this.hInput?.value,
    };
    // dispatch the action
    this._store$.dispatch(sendOrder({orderData}));
  }

  /**
   * Calculates the total price including discount if present (and checking what kind of discount it is).
   * Also sets the 'strikethrough'-price if a discount is given. This also checks if the used coupon has a limit on it's redemptions
   * and only applies it to the first `max_redemptions` items.
   * This changes the variables of this component and should be called whenever a change happens that might reflect in a change of prices.
   * @param trainings
   */
  calculateTotal(trainings: OrderableTraining[]) {
    // if there is a coupon, there is a bit more to do here
    if (!!this.discount) {
      // calculate the plain sum of all prices for the strikethrough-price
      this.strikethroughPrice = (1 / 100) * trainings.reduce((acc: number, training) => (acc += training.gross_price_amount_in_cents), 0);
      this.priceTotal =
        (1 / 100) *
        trainings.reduce((acc: number, training, index) => {
          // initial value is the normal price
          let price = training.gross_price_amount_in_cents;
          // if the redemptions are `null`, there is no limit. Otherwise apply until `max-redemptions` reached
          console.log(index, this.discount?.max_redemptions);
          if (!this.discount?.max_redemptions || index < this.discount?.max_redemptions) {
            // also, distinguish between percentual and total discount
            if (this.discount?.percent_off) {
              price *= 1 - this.discount.percent_off;
            } else if (this.discount?.amount_off) {
              price -= this.discount.amount_off;
            }
          }
          // reduce adds the price (be it with or without discount)
          return (acc += price);
        }, 0);
    } else {
      // if there is no coupon, just add all prices normally and set the strikethrough as undefined for the template
      this.strikethroughPrice = undefined;
      this.priceTotal =
        (1 / 100) *
        trainings.reduce((acc: number, training) => {
          return (acc += training.gross_price_amount_in_cents);
        }, 0);
    }
  }

  /**
   * Removes a training from the selection in the form. Used to enable the user to remove trainings from the basket.
   * @param training
   */
  removeTraining(training: OrderableTraining) {
    if (!!this.bookings) {
      const filtered = (this.bookings.value as OrderableTraining[]).filter((t) => t.slug !== training.slug);
      this.bookings.setValue(filtered);
    }
  }

  /**
   * Checks which nav-buttons should be shown for the stepper.
   * Used for the initial check and everytime the Stepper changes.
   * @param change
   */
  checkAvailability(change?: StepperSelectionEvent) {
    if (!!this._stepper) {
      const index = !!change ? change.selectedIndex : this._stepper.selectedIndex;
      this.isNextAvailable = index < this._stepper.steps.length - 1;
      this.isBackAvailable = index !== 0;
      this.isFinishAvailable = index === this._stepper.steps.length - 1;
    }
  }

  /**
   * The Input for user-data should prefill the invoice-fields.
   * Prefills only when targeted fields are empty!
   * @param field Identifies the changed field
   * @param change The Input
   */
  valueChanged(field: string, change: string) {
    switch (field) {
      case 'lname':
      case 'fname':
        // prefill the 'name' field for invoice with full name as soon as both given
        if (!!this.userFirstName?.value && !!this.userLastName?.value && this.invoiceName?.value === '') {
          this.invoiceName?.setValue(`${this.userFirstName.value} ${this.userLastName.value}`);
        }
        break;
      case 'email':
        if (this.invoiceEmail?.value === '') {
          this.invoiceEmail?.setValue(change);
        }
        break;
      default:
        break;
    }
  }

  /**
   * Opens the generic dialog with information about the prices.
   */
  openPriceInfo() {
    this._dialog.open(GenericDialogComponent, {
      // The generic dialog takes translated strings as values
      data: {
        title: this._translateService.instant('ORDER.PRICE_INFO.HEADING'),
        message: this._translateService.instant('ORDER.PRICE_INFO.TEXT'),
      } as GenericDialogData,
    });
  }

  redeemCoupon(code: string) {
    this._store$.dispatch(checkCouponCode({code}));
  }

  // this is needed to sync selection and basket more easily
  get selectedTrainings() {
    return this.bookingsStep?.get('bookings')?.value;
  }
  // getters needed for the validators
  get userFirstName() {
    return this.userStep?.get('firstName');
  }
  get userLastName() {
    return this.userStep?.get('lastName');
  }
  get userEmail() {
    return this.userStep?.get('email');
  }
  get invoiceName() {
    return this.invoiceStep?.get('name');
  }
  get invoiceEmail() {
    return this.invoiceStep?.get('email');
  }
  get invoiceAddress() {
    return this.invoiceStep?.get('address');
  }
  get invoiceZip() {
    return this.invoiceStep?.get('zip');
  }
  get invoiceCity() {
    return this.invoiceStep?.get('city');
  }
  get invoiceAcceptTerms() {
    return this.invoiceStep?.get('acceptTerms');
  }
  get bookings() {
    return this.bookingsStep?.get('bookings');
  }
  get coupon() {
    return this.bookingsStep?.get('coupon');
  }
  // selectors for honeypots
  get hCheckbox() {
    return this.bookingsStep?.get('hCheckbox');
  }
  get hInput() {
    return this.bookingsStep?.get('hInput');
  }
  // 'shortcuts' to the corresponding FormGroups
  get bookingsStep() {
    return this.orderForm.get('bookingsStep');
  }
  get userStep() {
    return this.orderForm.get('userStep');
  }
  get invoiceStep() {
    return this.orderForm.get('invoiceStep');
  }
}
