<form [formGroup]="signupFormCampaign" class="signup-form">
  <mat-form-field appearance="standard" class="syrupish">
    <input matInput #hInput name="a_password" formControlName="hInput" type="text" tabindex="-1" autocomplete="off" />
    <mat-checkbox formControlName="hCheckbox" color="accent" name="contact_me_by_fax_only" tabindex="-1" autocomplete="off"></mat-checkbox>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label translate>LABEL.FIRSTNAME</mat-label>
    <input matInput formControlName="firstName" type="text" required />
    <mat-error *ngIf="(firstName?.dirty || firstName?.touched) && firstName?.errors?.required">
      <span translate>ERROR.FIRSTNAME_REQUIRED</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label translate>LABEL.LASTNAME</mat-label>
    <input matInput formControlName="lastName" type="text" required />
    <mat-error *ngIf="(lastName?.dirty || lastName?.touched) && lastName?.errors?.required">
      <span translate>ERROR.LASTNAME_REQUIRED</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label translate>LABEL.EMAIL</mat-label>
    <input matInput formControlName="email" type="email" required />
    <mat-error *ngIf="(email?.dirty || email?.touched) && email?.errors?.required">
      <span translate>ERROR.EMAIL_REQUIRED</span>
    </mat-error>
    <mat-error *ngIf="(email?.dirty || email?.touched) && email?.errors?.validateEmail">
      <span translate>ERROR.EMAIL_VALID</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label translate>LABEL.SET_PASSWORD</mat-label>
    <input matInput formControlName="password" type="password" required />
    <mat-error *ngIf="(password?.dirty || password?.touched) && password?.errors?.required">
      <span translate>ERROR.NEW_PASSWORD_REQUIRED</span>
    </mat-error>
    <mat-error *ngIf="(password?.dirty || password?.touched) && password?.errors?.minlength">
      <span translate>ERROR.NEW_PASSWORD_MINLENGTH</span>
    </mat-error>
    <mat-error *ngIf="(password?.dirty || password?.touched) && password?.errors?.pattern">
      <span translate>ERROR.NEW_PASSWORD_PATTERN</span>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label translate>LABEL.PASSWORD_CONFIRM</mat-label>
    <input matInput formControlName="passwordConfirmation" type="password" required />
    <mat-error *ngIf="(passwordConfirmation?.dirty || passwordConfirmation?.touched) && passwordConfirmation?.errors?.required">
      <span translate>ERROR.NEW_PASSWORD_REQUIRED</span>
    </mat-error>
  </mat-form-field>
  <mat-error *ngIf="signupFormCampaign.errors?.passwordsMatch && (passwordConfirmation?.dirty || passwordConfirmation?.touched)">
    <span translate>ERROR.PASSWORDS_MATCH</span>
  </mat-error>
</form>
<button mat-flat-button [disabled]="signupFormCampaign.invalid" (click)="submit()" color="accent" translate>
  <span translate>SIGNUP.BUTTON</span>
</button>
<div class="mt-4 text-center accept-terms">
  <span translate>SIGNUP.ACCEPT_TERMS_PRE</span>
  <a class="textlink" target="_blank" rel="noopener noreferrer" href="https://troodi.de/impressum/#ds">
    <span translate>SIGNUP.TERMS</span>
  </a>
  <span translate>SIGNUP.ACCEPT_TERMS_POST</span>
</div>
